import React from "react";

export function TriangleLines4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <g>
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M14.46 4.12c-2.27 7-5.31 41.1-5.31 50.66S8.79 91.63 11.27 93s4.26-.35 3.9-1.77-2.12-11.34-2.12-17.18-.8-30.16.39-42.64S17.3 7.93 16.63 7 16.1 6.47 20 9.26s13.69 9 16.34 11.56S53.83 34 55.69 35.17 86.11 53 89.3 54.16 74.55 60.8 68.44 64.39 53.83 74.89 51 75.82s-14.48 7-16.47 7.7S18.76 89.63 18 90.43s-6.64 4-6.38 5.58 5.58-2 7.71-2.79 9.39-5.22 11.38-5.31 21.66-8.51 24-10.76S73.76 64.39 76.15 64s17.4-6.9 17.8-9-23.38-14.12-30.29-18.37S42.54 22.28 41.21 21 27.26 10.32 26.06 10.19s-10.71-8.81-11.6-6.07z"
        />
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M26.06 28.52c-.39 3.72-2.28 15.79-2.14 19.24C24 50.28 21 72.69 21.7 74.82s2.66 3.45 3.59 3.45 3.19-2.52 4.78-3 8.5-4.39 10-5 5.58-2.66 8-3 10.73-5 12.86-6.71a13.92 13.92 0 015.05-2.53c.26 0 1.06 1.46 2.12 1.46s2.66-1.19 2.26-1.72l-.4-.53s2.79-1.6 2.79-2.4-2.52-2-3.45-1.59-2.92 1.46-2.92 1.46-8.11-5.45-10.1-7S40.94 35.83 38 34.37s-10.89-7-10.76-6.11.27 2.26 3.19 4.38 8.1 5.45 9.56 6.51S55.7 50.3 57.15 51.64c1.79 1.66 6 3.45 5.58 3.72s-4 1.86-4.92 2.79S47 63.93 44.35 64.86s-6.87 2.93-8 3.32c-1.4.46-8 3.27-10.1 5.71-1.13 1.33.34-18.76.55-24.07.14-3.32 1.15-14.72 1-18.31-.13-3.29-1.59-4.34-1.74-2.99z"
        />
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M32.18 44.6c-1.6-.53-.2-1.86.33-2a1.18 1.18 0 01.46 0c1.2.19 3.21 1.75 4.72 2.79 1.73 1.2 7.24 5 7.84 5.65s2.85 2.55 3.83 3.37c.5.42 2.54 1 2.74 1.81S50 59 49.11 58.68c-.93.4-3.27 1-4 1.36-.52.28-3.52 1.5-4.32 1.76s-3.69 1.32-4.31 1.66-4-.45-4.12-1.13a17.26 17.26 0 01-.13-4.12c.06-.73.18-3.87.09-5.61-.06-1.36-.24-5.13-.16-5.61.2-1.26-.11-2.61.35-2.35s2 1.09 2.11 1.55.4 1.26.46 2c.13 1.66.56 4.85.64 5.67a41.36 41.36 0 01.23 6.29s3-1.19 3.85-1.39a12.89 12.89 0 003.3-1.22 11.35 11.35 0 014-1.57 35.74 35.74 0 01-4-3.46c-.5-.38-3.23-2.54-4.18-3.32"
        />
      </g>
    </svg>
  );
}
