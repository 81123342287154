import React from "react";

export function TriangleGroup3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M33.65 72.61c3.81-6 8.33-11.07 11.83-15.35 4.68-5.74 10-10.59 13.63-14.9s7-7.4 7.33-8.48a3.49 3.49 0 012.18-2.57c1.63-.84.59-1.42.24-1.67s1.83-1.5 2.8-2.53-.23-3.45-3.64-3.85.65-.78-1.2-1.88C64.12 19.77 54.31 18 52 17.92s-5.5-1.18-9.24-1.28-4.27-3-11.6-4.35S9.37 6.27 7.32 8.66s.68 4.79 1.59 6.63.63 3.08 2.63 9.86 2 10.75 4 17A103 103 0 0119 56.23c1 5 4.19 13.3 3.79 15.4s.33 3.12.66 3.22a20.64 20.64 0 012.07 2c.65.58 1.32 0 2.44-.68s1.68-.14 2.3.68 2.06-2.13 3.39-4.24zM87.26 91.57c-.36 1.25 2.62-.36 3.93-2.32s2.78-3.6 2-5.26-.93-.77-2.27 1.59-3.27 4.63-3.66 5.99zM35.14 85.14c1.25-3 9.45-11.87 10.86-14.07S56 60 58.05 55.69s8.83-9.22 9.47-12.27 5.49-6 8-8.29 3.82-6.62 6.2-7.07 2 .91 1.48 2.91-3.36 5.44-5.65 7.43-11.77 13.05-14.79 17.2C46.72 77.68 33.21 89.75 35.14 85.14zM52.68 83.58c-2.33 4.73-1.24 5.3 3.67-2s8.41-7.46 11.23-11.86a44.39 44.39 0 015.86-6.93c2.06-2.09 4.6-5.78 5.63-7s4.64-3.42 5.66-6.05.34-5.1-1.84-3.75S72.55 58 70 61.32 58.69 74.32 57.28 76a35.39 35.39 0 00-4.6 7.58zM67.38 85.05c-1.44 2-1.94 4.61-1.13 4.65s4.31-5.06 5.72-7.26 6.94-7.2 8.27-9.57 6.62-5.67 6.91-8.18-.5-4.69-2.15-2.62a67.36 67.36 0 00-5 7.14c-1 1.81-6.49 6.41-8 8.44s-3.14 5.35-4.62 7.4zM80.25 84.15c-2 2.68-2.87 7-1.71 6.49s2.54-3.68 4.31-5.06 4.71-6.42 6.57-8 2.65-5.71 1-6.2-2.79 2.22-4.74 5.45-2.56 3.49-5.43 7.32z"
      />
    </svg>
  );
}
