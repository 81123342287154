import React from "react";

export function Composite1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M15 14.77c.42 9.52-.72 18.45-1.29 25.79-.77 9.84-2.89 19.17-3.61 26.66s-2 13.4-1.58 14.84a4.67 4.67 0 01-.43 4.47c-1.16 2.16.43 2 1 2s-.87 3-1.16 4.9 2.88 3.6 6.92 1.44-.13 1.35 2.74 1.16c4.17-.29 16.28-5.77 18.87-7.5s6.92-2.88 11.1-5.62 6.91 0 16-4S91.9 69 92.34 64.77 88 60 85.56 58.72s-3-2.88-10.37-8.79S64.82 39.7 57.9 34.37a135.8 135.8 0 01-14.41-12.82c-4.9-4.76-14.7-11.39-15.85-14s-2.74-3.17-3.17-3-2.59-.44-3.74-.55-1.44 1-2.16 2.59-1.73 1.44-3 1-.75 3.87-.57 7.18z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_2"
        d="M29.41 22.81c1.23-2.13 1.27-4.06-1.42-4.42s-4.36-1-5.06.29-2.4 4.79 0 5.58a24 24 0 003.32 1.08c1.04.19 2.26-.97 3.16-2.53zM23.25 30.91c2.39 0 4.87.64 4.92 3.84s-2.5 4.38-4.36 4.83-5.12-3.33-4.21-4.68.76-4.05 3.65-3.99zM32.8 33.43c2.48.94 2.84 2.84 5.3 1.77s3.35-3.88 2.81-5.11-2.83-2.54-5.1-2.19-4 .79-4.2 2.24-.89 2.5 1.19 3.29zM34 42.09c2.72.09 4.46.75 4.59 2.19s.78 3.3-1.27 4.26-3.47 2.65-4.72 2.05-1.47-1.53-2.81-1.19-2.79-3.78-.63-5.4 2.76-2 4.84-1.91zM20 46.57a9.59 9.59 0 013.73 1c1.5.46 1.47 1.42 1.08 2.66s-1.58 4.06-3.23 4.19-6.45-2.26-6.58-4 1.18-3.79 5-3.85zM45.68 37c-2-.27-2.79.15-3.16 2.74s2 4.93 4.54 5.3 5.52-3.09 5.08-5-.76-2.26-1.9-2.24-1.57-1.31-2.4-1.2-2.16.4-2.16.4zM61 46.17c-.46.4-.93.77-1.36 1.12-1.43 1.18-2.4 2.14-.94 3.47 1.89 1.72 4.21 4.58 6 2.38s2.83-3.77 2-5.21S62.35 45 61 46.17zM47.28 51.89c-1 .12-2.08-.8-3.18 1.5S42.83 58 44.61 59s4.88 1.67 5.59.42 2.8-4.12 1.13-6.26-3.21-1.37-4.05-1.27zM35.72 58.7c-3.25-1.24-4.59-2.51-5.88.62s.64 1.54.17 3.82 3.05 3.15 4.59 2.91 4.25.45 4.38-3.79-1.7-2.97-3.26-3.56zM20.08 62.58c-2.76.05-4.37-1.68-6 .11a3.66 3.66 0 000 5.06c1 .91 2.84 2.85 4.37 2s3.49-1.4 3.87-3.27.75-3.95-2.24-3.9zM17.66 78.64c-3.32-.27-3.69 1.72-4.4 2.35s-1.38 3.64-.54 4.87 2.61 1.2 4 1.07 4.65.23 4.62-2 1.29-2.61.13-4.14a4.12 4.12 0 00-3.81-2.15zM33.38 73.31c-2.55-.08-4.27 3.79-3.08 6.77s6 5.07 8.08 1.41 3-5.42 2-7.06-4.82-1.05-7-1.12zM48.39 68.51c-2.54 1.39-4.57 4.41-2.9 5.52s2.59.58 3.12 1.19 4.84-.7 5.75-2.17 2-3.86 0-4.13-4.02-1.48-5.97-.41zM62 62.69c-1.11.86-3.36 3.16-.75 5s5 1.78 6 .11 1.37-4.26.74-5.29-1.87-.07-3-.15-2.14-.3-2.99.33zM76.51 57.07c-.8-.09-1.62 2.09-2.85 2.53s-.76 3.73.69 4.33 4.11 4.27 6.61-.22 1.69-4.06-.08-5.17a9.07 9.07 0 00-4.37-1.47z"
      />
    </svg>
  );
}

