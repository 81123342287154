import React from "react";

export function BlobGroup3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M28.34 12.49C30 9.75 30.09 7.25 26.6 6.71S21 5.36 20 7s-3.22 6.18-.13 7.25 2.95 1.21 4.3 1.48 2.96-1.23 4.17-3.24zM19.47 21.22c3.1.11 6.32.94 6.32 5.11S22.43 32 20 32.51s-6.58-4.43-5.37-6.18 1.08-5.24 4.84-5.11zM31.84 24.72c3.16 1.28 3.62 3.76 6.85 2.41s4.43-5 3.76-6.58-3.63-3.36-6.58-3-5.24.94-5.51 2.83-1.21 3.26 1.48 4.34zM33.18 36c3.53.18 5.78 1.08 5.91 3s.94 4.3-1.74 5.51-4.57 3.32-6.19 2.49-1.88-2-3.62-1.61-3.5-5-.68-7 3.63-2.52 6.32-2.39zM14.9 41.51c2.22 0 3.63.94 4.84 1.35 1.93.64 1.88 1.88 1.35 3.49s-2.15 5.24-4.3 5.38-8.34-3.09-8.47-5.38 1.61-4.84 6.58-4.84zM48.5 29.69c-2.55-.41-3.63.13-4.17 3.49s2.56 6.45 5.78 7 7.26-3.9 6.72-6.32-.94-3-2.42-3-2-1.75-3.09-1.62-2.82.45-2.82.45zM68.25 41.92c-.6.51-1.22 1-1.78 1.42-1.9 1.5-3.17 2.73-1.31 4.49 2.42 2.28 5.38 6.05 7.8 3.22s3.76-4.83 2.68-6.71-5.64-3.9-7.39-2.42zM50.25 49c-1.35.13-2.69-1.08-4.17 1.88s-1.75 5.91.54 7.26 6.31 2.28 7.26.67 3.76-5.24 1.61-8.06-4.14-1.82-5.24-1.75zM35.06 57.64c-4.19-1.68-5.91-3.36-7.66.67s.81 2 .14 5 3.89 4.17 5.91 3.9 5.51.67 5.78-4.84-2.15-3.92-4.17-4.73zM14.64 62.34c-3.58 0-5.65-2.28-7.8 0a4.76 4.76 0 00-.13 6.59c1.34 1.21 3.63 3.76 5.64 2.68s4.57-1.74 5.11-4.16 1.07-5.11-2.82-5.11zM11.14 83.17c-4.31-.43-4.84 2.15-5.78 3s-1.88 4.7-.8 6.31 3.36 1.62 5.1 1.48 6.05.41 6.05-2.55S17.46 88 16 86s-2.17-2.56-4.86-2.83zM31.7 76.59c-3.31-.16-5.64 4.83-4.16 8.73S35.2 92 38 87.34s4-7 2.82-9.14-6.3-1.48-9.12-1.61zM51.32 70.67c-3.33 1.76-6 5.65-3.9 7.13s3.36.8 4 1.61 6.31-.81 7.52-2.69 2.69-5 .14-5.37-5.2-2.02-7.76-.68zM69.19 63.42c-1.46 1.09-4.43 4-1.07 6.45s6.45 2.42 7.79.27 1.88-5.51 1.08-6.86-2.42-.13-3.9-.27-2.82-.4-3.9.41zM88.14 56.43c-1-.13-2.15 2.69-3.76 3.22s-1.08 4.84.81 5.65 5.24 5.64 8.6-.14 2.28-5.24 0-6.71a11.77 11.77 0 00-5.65-2.02z"
      />
    </svg>
  );
}
