import React from "react";

export function LineGroup7() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M96.19 73.9a13.25 13.25 0 01-10.54 6.42c-5.38.55-10.69-.18-16 .22-4.92.38-9.85.76-14.77 1.17-6.25.53-12.5 1.31-18.77 1.59-5.6.26-11.23 0-16.85-.06-3.14 0-6.28.11-9.41 0a22.7 22.7 0 01-5-.74c-1.07-.28-1.45-1.3-.48-2.07 1.47-1.16 2.66-2.65 4.8-3a32.32 32.32 0 006.88-2.1c3.52-1.42 7.17-.88 10.77-1 4.77-.17 9.55 0 14.31-.32 6.2-.36 12.47-.56 18.57-1.62 5.87-1 11.69-.47 17.52-.7 4.91-.2 9.85.41 14.73-.7a1.77 1.77 0 011.34.29c1.03.85 1.97 1.78 2.9 2.62zM75.43 63.67c-4.79.27-9.62.43-14.43.85-3.6.32-7.16 1.25-10.76 1.39-5.55.21-11.12 0-16.68-.07-3.2-.06-6.4-.23-9.58-.49a31.68 31.68 0 01-3.76-.92c-.33-.07-.73.23-1.07.17-.7-.13-1.69-.18-2-.62a2.34 2.34 0 01.3-2 4.09 4.09 0 011.88-1c5.17-1.86 10.57-1.43 15.92-1.56 4.7-.12 9.41-.22 14.1-.54s9.1-1 13.65-1.34c3.61-.3 7.23-.36 10.85-.58 2.07-.12 4.15-.26 6.22-.51 1.51-.19 2.34.6 3.08 1.73s-.2 1.71-.78 2.45c-1.75 2.26-4.32 2.58-6.94 3.04zM28.3 47.28a25.14 25.14 0 014.6-2.22 19.86 19.86 0 015-.25c1.73 0 3.47.13 5.21.2s3.37.22 5.05.14c3.86-.19 7.71-.54 11.57-.76 4.43-.25 8.87-.6 13.31-.57 2.26 0 3 2.12 1.48 3.82a7.3 7.3 0 01-5.63 2.89c-5.78.25-11.53 1.3-17.31 1.64A50.65 50.65 0 0136 50.43a21.66 21.66 0 00-3.92-.64 4 4 0 01-3.78-2.51zM28.11 34.18c.66-.89 1.1-2.11 1.89-2.41a21.09 21.09 0 016.41-1.63c7.18-.34 14.36-.43 21.55-.53a74.4 74.4 0 017.9.29c2.52.22 2.75 1.58 1.67 3.77-1.37 2.76-3.53 3-6 2.95-7.38-.14-14.76-.26-22.13-.47a50.33 50.33 0 01-6.83-.69 35.25 35.25 0 01-4.46-1.28zM47.42 16.63c5 0 10-.07 15 .06a3.7 3.7 0 012.49 1.38c.69.88-.14 1.62-.76 2.46-2.86 3.87-7 3.72-11 3.5a109.71 109.71 0 01-12.47-1.76c-.72-.12-1.85-1-1.84-1.54a3.13 3.13 0 011.36-2.4c2.26-.98 7.17-1.93 7.22-1.7z"
      />
    </svg>
  );
}

