import React from "react";

export function TriangleLines1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <g>
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M3.28 28.64c2.49-3.11 6.08-3 9.57-3.5s6.9-1.59 10.35-2.39c6.11-1.4 12.25-2.71 18.34-4.19 2.76-.67 5.41-1.73 8.15-2.5 1.75-.5 3.55-.81 5.35-1.14 2.59-.46 5.22-.76 7.79-1.32 2.89-.63 5.71-1.46 8.75-1.38a25.76 25.76 0 005.07-.87c2-.37 4.08-.62 6.06-1.14 1.73-.46 3.36-1.28 5.07-1.81a24.19 24.19 0 013.08-.55c1.1-.19 2.2-.4 3.31-.57.79-.13 1.59-.22 2.39-.33 0 1 .34 2.11 0 3-.72 1.76-2 3.3-2.69 5.07-1 2.47-1.61 5.06-2.38 7.6-.29 1-.53 2-.84 2.95-1.19 3.69-1.56 7.58-3.32 11.18-1.29 2.66-1.59 5.79-2.43 8.68-.66 2.31-1.52 4.56-2.19 6.86C81.75 55.49 81 58.78 80 62c-.8 2.6-1.87 5.12-2.73 7.71a41.7 41.7 0 00-1.1 4.29c-.54 2.46-1 4.94-1.55 7.4-.62 2.69-1.71 5.34-1.87 8-.13 2.37-1.08 3-3.1 3.33-1.79.31-3.4.54-4.91-.76-1-.84-2.14-1.46-3.08-2.31a33.65 33.65 0 01-2.88-3.09c-1.69-2-3.37-4-5-6.09A13.54 13.54 0 0151.34 77c-.76-2.11-2.49-2.94-4.07-4.18A15.36 15.36 0 0142.91 68c-.95-1.8-2.78-3.19-4.36-4.6-2-1.81-4.22-3.42-6.29-5.18-2.89-2.46-5.59-5.17-8.65-7.41a31.8 31.8 0 01-7-7.2c-.39-.56-1.22-.77-1.75-1.25-1.57-1.42-3.06-2.91-4.63-4.32a12 12 0 00-3-2.36 4 4 0 01-2.67-3.93A8.7 8.7 0 003.28 29zm64.09 55.22c.83-3.76 1.71-7.65 2.55-11.55.49-2.28.72-4.63 1.41-6.84 1.9-6 4.71-11.72 5.71-18.08.52-3.29 2.24-6.39 3.29-9.62.85-2.6 1.54-5.25 2.24-7.9.45-1.75.68-3.56 1.21-5.29.73-2.35 1.71-4.63 2.45-7 .3-1 0-1.81-1.31-1.49-3.25.81-6.48 1.71-9.73 2.52a15.24 15.24 0 01-2.34.27 46.56 46.56 0 00-6.16.61c-2.43.56-4.76 1.56-7.15 2.33a3.41 3.41 0 01-1.35.1A9.33 9.33 0 0052.31 23a8.52 8.52 0 01-2.41.84c-4.82 1-9.67 1.93-14.47 3-1.91.43-3.7 1.42-5.6 1.87C24.67 29.94 19.48 31 14 32.19a10.07 10.07 0 001.19 1.46c1.44 1.18 3 2.22 4.37 3.49s2.31 2.71 3.61 3.93c1 1 2.35 1.61 3.41 2.54 2 1.77 3.86 3.74 5.93 5.45 2.86 2.37 5.89 4.54 8.8 6.87a16.92 16.92 0 012.67 2.68c1.28 1.61 2.14 3.68 3.69 4.94 3.39 2.74 6.58 5.51 8.95 9.29 1.9 3 4.45 5.62 6.71 8.4.94 1.2 1.33 3.16 4.04 2.62z"
        />
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M73.15 28.81c-.68-1.81-2.15-1.61-5.46-.52-2.9 1 0 0-8.29 1.93s-9 3.32-18 3.59-4.17 6-.21 9.19 5.45 5.17 9 8.6a90.16 90.16 0 017.35 7.88c1.39 1.65 2.62 3 5.7.2 2.77-2.48 1.76-5.56 2.59-9.43S68.9 42 70.14 39c1.4-3.47 2.76-4.11 4.15-6.32s-1.14-3.87-1.14-3.87zM61.44 42.64c-.37 1.19-2.7 8.18-2.39 8.5s-3.16-1.7-6.73-5.81c-1-1.19-6-5.44-6-5.44s19-5 19.27-4.87-3.79 6.43-4.15 7.62z"
        />
      </g>
    </svg>
  );
}

