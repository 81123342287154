import React from "react";

export function LineGroup4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M68.38 36.7c0-1.45.73-2.07 2-1.91 2.15.27 4.3.58 6.46.66 3.88.16 7.76.17 11.64.22 1.25 0 2.5-.13 3.75-.06a2.92 2.92 0 012.91 4c-.22.8-.46 2-1 2.23a11.71 11.71 0 01-4.89 1.46c-3.61 0-7.21-.53-10.81-.86-2.29-.22-4.58-.48-6.87-.73a2.62 2.62 0 01-2.67-2.37c-.16-.74-.3-1.49-.44-2.23a1.51 1.51 0 01-.08-.41zM51 64.68c-2.23.1-4.45.31-6.68.26a38.1 38.1 0 01-6.13-.59 2.51 2.51 0 01-2.32-2.3 12.78 12.78 0 00-.61-2.55c-.44-1.46-.36-1.25 1-1.55 2.24-.5 4.25.79 6.47.43a47.59 47.59 0 016.55-.28h4.53c1.27 0 2.44.54 2.52 2.18.1 2.06.19 2.27-1.68 3.2-1.14.56-2.36 1-3.54 1.45zM34.44 34.91c2.75.43 5.34.92 8 1.21 2.82.32 5.66.39 8.48.71 1.56.17 3.09.69 4.64 1 1.35.25 1.62 1.36 1.72 2.35 0 .44-.68 1.08-1.18 1.46-1.38 1-3 1-4.68 1.23-4.46.56-8.79-.41-13.15-.94-2-.23-2.58-1.1-2.87-3.08a25.11 25.11 0 00-.8-2.91 7.56 7.56 0 01-.16-1.03zM23.05 62.81l-4.81 1.45a18.29 18.29 0 01-3.68 1c-2.56.19-5.15.82-7.71 0-.38-.13-.89-.35-1-.67a28.91 28.91 0 01-1.3-3.84c-.07-.29.37-.92.71-1a20.31 20.31 0 013.94-1.12 44.88 44.88 0 015.32-.42c1.32 0 2.65.09 4 .14.08 0 .19.1.23.07 1.43-.82 2.51.08 3.25 1a9.9 9.9 0 011.15 3 3.39 3.39 0 01-.1.39zM73.77 56.26c3-.26 6-.61 9-.76 1.44-.08 2.92.39 4.35.26s2.09.66 2.48 1.87a1.79 1.79 0 01-2 2.5c-.28 0-.85.37-.82.49.15.89-.54.84-1 1a15.24 15.24 0 01-3.76.94 61.09 61.09 0 01-8.23-.44c-1.36-.17-2.15-4.7-1.07-5.65.23-.21.66-.21 1-.3zM17.94 43.05H9.53a1 1 0 01-.7-.55q-.67-2.16-1.23-4.35c-.26-1.05.35-1.51 1.45-1.44a23.12 23.12 0 005 0 14.14 14.14 0 018.21 1.15 3.37 3.37 0 011.27 2c.08.25-.6.92-1.06 1.15-1.69.78-3.42 1.53-4.53 2.04z"
      />
    </svg>
  );
}
