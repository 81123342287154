import React from "react";

export function BlobGroup1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M47.68 90.4c3.94-3.37 3.07-8 1.5-12a8 8 0 00-5-4.33c-3.34-.66-8.84 4.3-9.46 8.27s1.46 6.91 5.14 8.6c2.19 1.06 6.29.78 7.82-.54zm7.51-38c0 5.6 3 9.37 7.26 9.24 3.06-.09 6.8-5.26 6.76-9.35 0-4.88-2.87-9-6.13-8.81-3.53.17-7.9 5.11-7.89 8.95zm-.87-35.14a7.18 7.18 0 00-7.1-6.91c-2.8.08-6.82 5.14-6.6 8.3.29 4.36 3.64 7.82 7.6 7.87 3.65.07 6.21-3.82 6.1-9.23zM85.77 21a35.06 35.06 0 01-2.17 6.09c-.58 1.41-5.58 2-7.33.25-3-3-4.9-5.86-5.44-9.76-.64-4.65 5.08-7.28 9.81-5.3 1.78.75 4.14 1.06 5.13 2.72s.4 4 0 6zM13.69 18.89a13.48 13.48 0 011.83-6.58c1.35-2 2.92-3.92 4.46-3.9a8.84 8.84 0 016.4 4.1 11.46 11.46 0 01.5 8.09c-.73 2.4-2.19 4.7-4.86 5s-3.3-.91-5.42-2.79c-.76-.68-2.75-1.92-2.91-3.92z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M39.81 91c-3.68-1.69-5.75-4.61-5.14-8.6s6.12-8.93 9.46-8.27a8 8 0 015 4.33c1.57 4 2.18 8.32-1.5 12C47 91 42.05 92 39.81 91zM55.19 52.43c0-3.84 4.36-8.78 7.89-8.92 3.26-.14 6.09 3.93 6.13 8.81 0 4.09-3.7 9.26-6.76 9.35-4.28.13-7.23-3.67-7.26-9.24zM38.11 52.1c.91 4-4.72 8.43-8.28 8.88-2 .24-6.33-2.93-6.55-4.91-.36-3.28.59-6.19 2.24-9.35.52-1 4.13-2.37 6-1.71a9.61 9.61 0 016.59 7.09zM54.32 17.29c.11 5.41-2.45 9.3-6.1 9.26-4-.05-7.31-3.51-7.6-7.87-.22-3.16 3.8-8.22 6.6-8.3a7.18 7.18 0 017.1 6.91z"
      />
    </svg>
  );
}

