import React from "react";

export function TriangleGroup2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M34 5.19c3.71-3.12 10.9 2.19 16.21 1.56s11.56-2.81 14.06-.93S60.21 16.13 55.83 23s-2.83 9.55-5.31 9.69C44.9 33 31.37 7.36 34 5.19zM36.46 41.12c1-4.31 29.37-2.81 29.69-1.25s-6.57 11.25-8.75 14.69S54 63 51.46 63.93s-5.31-6.87-6.25-9.06-9.37-10.94-8.75-13.75zM66.15 70.49c1.15 2.17-2.57 6.81-5 12.19-2.82 6.22-4.6 13.12-5.94 13.12-2.5 0-8.44-8.44-10.31-14.06s-8.44-8.44-5.31-10.62c2.36-1.65 10-1.25 15.62-.63 5 .55 8.12-.62 10.94 0"
      />
    </svg>
  );
}
