import React from "react";

export function Blob3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M89.63 38.37C87.9 28.36 74.22 21.62 71 18.73s-8.86-8.47-15.6-10.39S40.54 5 36.1 9.35c-3.31 3.27-11.16-.77-14 2.12s-15.26 18.29-12.18 36S18 70.57 23.4 77.31 40.45 90 44.57 92.13s12.13 2.12 17.52-1.92S78.85 85.94 85 73.61s6.36-25.22 4.63-35.24zm-40.82 17c-1.54 10.78-4.05 7.51-6.16 12.32s-10.21.77-14.83-5.39S18 34.33 19.54 30.48s-.77-10.78 9.05-6.55c2.07.9 7.12 4.24 8.86 7.13s12.9 13.48 11.36 24.26z"
      />
    </svg>
  );
}
