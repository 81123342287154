import React from "react";

export function Triangle1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M81 6.78c-8.43 3.88-16.89 6.14-23.73 8.33C48.16 18 39 19.57 32 21.67s-12.82 3.12-14 4S9.3 29 7.51 29.38s-1.74 2.72.09 5.13c.79 1 12.32 17.09 14.83 18.79s5.14 5.17 9.15 7.93 2.55 6.24 9.53 12.94S60.52 96.1 64.45 95s2.69-5.64 3-8.33 1.44-3.84 4.07-12.67 5.4-13.12 7.66-21.32A132.5 132.5 0 0185.44 35c2.48-6.17 4.85-17.44 6.77-19.44s2.06-4.81.7-7.56c-.5-1-1.54-2.94-3.23-3A22.63 22.63 0 0081 6.78z"
      />
    </svg>
  );
}
