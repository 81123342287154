import React from "react";

export function TriangleGroup1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M9.35 66.09c-1.36-1.14-1 3.45.74 6.16s3 5.42 5.42 5.42 1.48-.74-.74-3.7-3.94-6.65-5.42-7.88zM45.82 4.74c3 3.16 9.6 18.23 11.58 21.19s8.37 18.48 12.6 23.4 6.65 16 10.1 18.48 4.4 10.1 5.9 14.54 6.15 8.37 5.42 11.58-2.22 2-4.44.24S82 87 80.8 83.09 71 61.26 67.5 55.25C48.77 23.22 41.13-.19 45.82 4.74zM38.18 27.41c-4.61-5.48-5.91-4.44.49 5.66s4.68 14.54 8.62 20.45a59.87 59.87 0 015.42 11.09c1.48 3.69 4.69 8.87 5.67 10.84s1.73 7.64 4.44 10.35 6.18 3.2 5.66-.25-9.36-19.46-12.07-24.39S46.42 40 45.08 37.26c-1.23-2.46-2.96-5.17-6.9-9.85zM28.32 44.9C26.65 42 23.64 40 23.15 41s3.94 8.09 5.91 11 5.18 12.57 7.39 15.53 3.45 11.37 6.41 13.09 6.16 2 4.43-1.23a89.77 89.77 0 00-6.16-10.1C39.41 67.07 36.7 57.71 35 54.75s-4.95-6.89-6.68-9.85zM22.41 61.41c-2.23-4-7.14-7.4-7.14-5.67s3.2 5.17 3.94 8.13 5.42 9.36 6.4 12.56 5.67 6.41 7.15 4.69-1.23-4.69-4.19-8.87-2.96-5.18-6.16-10.84z"
      />
    </svg>
  );
}
