import React from "react";

export function TriangleLines3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M48.18 18.42c.78.64.9 1.25 2.13 2.73a41.3 41.3 0 017.15 14.15c2.36 8.32 8.8 20 12 24.32 3.57 4.81 14.59 20.31 10.39 20.91s-17.82-1.35-24.56-.15c-4.72.84-15-1.5-21.56-1.5S17 81.73 17.57 79.93s11.72-15.06 15-24c2.73-7.47 5.26-10.22 8-17A107.6 107.6 0 0046.33 20c.36-1.92-.43-2.64-.69-2.69-.62-.13-1.6-.44-2-.2-1 .55-2.46 1.8-4.77 7.38-3.6 8.69-6.59 9.29-7.19 15.28S26 51.18 17.57 64.06 6.79 80.53 6.49 82.93s-.6 2.09 3.59 3.59 7.73 1.83 10.79.3c3.59-1.8 19-1.2 26.35 0s35.65 2.7 41.94 1.2c3.14-.75 5.55-3.6 4-6.29a70 70 0 00-4.94-8.09C83.85 68.5 81.37 58.07 76 53.28s-9-18.6-11.7-22.77c-6-9.28-7.84-16-10.79-17.07-2.54-.9-7.13-5.1-8.38 1.8-.07.37 1 1.65 1.21 1.79.95.68 1.46 1.08 1.84 1.39z"
        />
    </svg>
  );
}
