import React from "react";

export function LineGroup1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M31.69 75.56c4.21-6.67 9.19-12.2 13.05-16.92 5.16-6.32 11-11.67 15-16.42s7.69-8.16 8.08-9.35 4.84-5.43 5.76-7.46c.65-1.43.37-4.23-5.32-5.53-3.86-.89-13.05-3.33-15.62-3.82s-6.83-2.08-11-2.19S37 10.6 28.93 9.07s-24-6.63-26.26-4 .7 5.27 1.75 7.31.7 3.39 2.91 10.87S9.56 35.1 11.74 42a112.06 112.06 0 013.75 15.5c1.09 5.54 4.09 14.63 4.18 17 .09 2 2.29 5.07 3 5.71s3.51.58 4.75-.16a13.6 13.6 0 004.27-4.49zM90.79 96.46c-.4 1.38 2.89-.39 4.34-2.56s3.06-4 2.26-5.79-1-.86-2.51 1.75-3.65 5.14-4.09 6.6zM33.35 89.37c1.36-3.26 10.4-13.08 12-15.5s11-12.25 13.29-17S68.32 46.74 69 43.38s6.06-6.59 8.87-9.13 4.21-7.3 6.83-7.8 2.19 1 1.63 3.21-3.7 6-6.23 8.19-13 14.38-16.34 19c-17.65 24.3-32.55 37.6-30.41 32.52zM52.67 87.66c-2.57 5.21-1.37 5.83 4-2.22S66 77.23 69.1 72.37a49.93 49.93 0 016.46-7.64c2.27-2.3 5.06-6.36 6.21-7.74s5.11-3.77 6.24-6.66.37-5.62-2-4.13S74.57 59.5 71.8 63.11 59.3 77.45 57.74 79.32a39.38 39.38 0 00-5.07 8.34zM68.87 89.27c-1.58 2.18-2.13 5.09-1.24 5.13s4.76-5.58 6.31-8 7.65-7.94 9.12-10.55 7.29-6.26 7.61-9-.54-5.21-2.36-2.89a74.93 74.93 0 00-5.5 7.87c-1.08 2-7.15 7.07-8.78 9.31s-3.52 5.86-5.16 8.13zM83.06 88.29c-2.21 2.95-3.16 7.71-1.88 7.15s2.8-4.07 4.75-5.58 5.19-7.08 7.25-8.85 2.91-6.3 1.15-6.83-3.08 2.44-5.23 6-2.87 3.88-6.04 8.11z"
      />
    </svg>
  );
}
