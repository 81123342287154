import React from "react";

export function Triangle2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M41.05 4.15C36.81 14.74 23.5 49.47 21.46 52.4S.4 91.58 3.62 91.58s26-2.34 33.33-3.8 56.44-8.48 57.9-10.53-23.1-30.7-28.66-38.89S42.22 1.23 41.05 4.15zM96 81.93c-.77.62-13.27 2.83-16.94 3.14-2.18.19-7.39 1.2-13.78 2.14-4.25.62-8.9.9-14 2-5.69 1.25-24 4.09-30.12 5S10.35 95.42 4.57 96c-2.27.24-.66-2.12 1.68-2.42S19.41 92.16 27.3 91s25.15-2.93 28.7-4.39 7-.72 9.58-.93 17.55-2.58 19.58-3.18a51.5 51.5 0 017.9-1.5c4.4-.37 4.11 0 2.94.93z"
      />
    </svg>
  );
}
