const colors = [
    'bazooka',
    'mintfoam',
    'majorelle',
    'coral',
    'heinz',
    'sol_lewit',
    'sol_lewet',
    'neon',
    'slate',
    'raincloud',
]
export default colors
