import React from "react";

export function SquareLines1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M21.4 40c1.6 4 .4 17 1.3 21.4.7 3.6-2.3 17.8 1.3 19.5 3.8 1.8 9.2.3 13.7.6 5.2.3 9.5 1.1 14.2-.4 3.1-1 9.3-.1 19.2.4 2.6.1 8.8 0 9.8-1.7s.1-7.4-.4-11.6c-.7-6.1.8-20 1-25.8.1-4.5-.3-14.4-.6-18.4-.2-3 1.3-5.2-3.2-5.1-5.1.1-24.4.5-28.3-.4-3.7-.8-18.8-.3-22.5 0-4.3.3-4.1 1-5.1 2.7S20 36.6 21.4 40zm3.8 19.8c0-3 .4-17.3.4-19.9s-.6-12.7.2-14.1 4.6-3.1 8.2-2.7 20.9 1.6 29.3 1c3.4-.2 6.4-1.3 10.1-.2 3.7 1.1 5.1 2.4 4.4 4.8-.7 2.4-.7 8.1-.6 12.2.1 6.2.1 19 .1 22.3 0 3.4-.2 11.1-1.1 12.6-.8 1.4-5.8.5-8 1s-9.6-.4-12.4-.5c-2.8-.1-10.1-.6-14 0s-10.5.4-14.1.5c-3.6.1-2.5-13.9-2.5-17z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M89.2 5.9c-6 .2-16.9-.2-23.3.8s-18.5.2-23.1 0S26 5.1 22.4 5.9 10.7 5.1 9.3 7.5 7.5 22.6 7.5 28.2s0 26.6.2 36.8c.1 6.8.2 16.1-1 20.1s1 6.2 7.2 7.9 11.1 0 16.7-.4c13.9-1 42.3.8 48.3 1.6s12.1-2 13.5-4.4.4-18.9.4-23.3.6-27.8.6-32.8c0-5.1 1.8-28-4.2-27.8zm-2.1 80.5c-1.2 2.1-1 3-6.4 3.4-4.7.4-23.8 1.1-28.4 0-4.9-1.1-29.3-.7-35.7-.5-5.8.2-3.9-2.7-4.1-6.4-.4-5-.9-17.5-.7-23.2.2-7.2 2.2-24.8 1.2-32.5-.6-5.3-1.8-12.5-.5-14.6s9-2.3 12.3-2.1c12.4.5 20.3 1.6 24.2.3 5.9-2 11.2-.9 17.9-.5 5.8.3 12.5-1.6 17.3.7 4.5 2.1.7 20.1 1.6 24.6 1.1 5.5-.4 22 1.6 27 1.7 4.3.9 21.6-.3 23.8z"
      />
    </svg>
  );
}
