import React from "react";

export function Composite6() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M9.5 31.48c2.16-2.69 5.26-2.6 8.29-3s6-1.38 9-2.07c5.3-1.22 10.62-2.35 15.9-3.64 2.39-.58 4.7-1.5 7.07-2.17 1.52-.43 3.08-.7 4.64-1 2.24-.41 4.52-.66 6.75-1.15a31.8 31.8 0 017.59-1.2 21.91 21.91 0 004.4-.75c1.75-.32 3.53-.54 5.25-1 1.5-.4 2.91-1.11 4.39-1.57a20 20 0 012.68-.47c.95-.17 1.91-.35 2.86-.5.69-.11 1.39-.19 2.08-.29a6.66 6.66 0 010 2.56c-.63 1.53-1.73 2.86-2.33 4.4-.88 2.14-1.43 4.37-2.07 6.59-.26.85-.47 1.71-.74 2.55-1 3.21-1.35 6.58-2.87 9.7-1.12 2.3-1.38 5-2.11 7.53-.57 2-1.32 4-1.9 5.94-.83 2.82-1.5 5.67-2.37 8.47-.7 2.26-1.63 4.44-2.37 6.69a35.81 35.81 0 00-.92 3.74c-.46 2.13-.86 4.28-1.35 6.41-.53 2.33-1.48 4.63-1.61 7-.12 2-.94 2.58-2.69 2.89-1.56.27-3 .46-4.26-.67-.84-.72-1.85-1.25-2.67-2a26.34 26.34 0 01-2.49-2.68c-1.48-1.74-2.93-3.49-4.35-5.27a12.39 12.39 0 01-2.11-3c-.66-1.83-2.16-2.55-3.53-3.63a13.35 13.35 0 01-3.78-4.23c-.82-1.56-2.41-2.76-3.78-4-1.75-1.57-3.65-3-5.45-4.5-2.51-2.13-4.85-4.48-7.5-6.42A27.36 27.36 0 0121 44.47c-.35-.47-1.06-.66-1.53-1.08-1.35-1.22-2.65-2.52-4-3.74a10.13 10.13 0 00-2.62-2 3.45 3.45 0 01-2.31-3.41 7.58 7.58 0 00-1.04-2.41zm55.56 47.88c.72-3.26 1.48-6.63 2.21-10 .42-2 .62-4 1.23-5.94 1.64-5.22 4.08-10.16 4.94-15.67.45-2.86 2-5.54 2.86-8.34.74-2.26 1.33-4.56 1.94-6.85.39-1.52.59-3.09 1.05-4.59.63-2 1.48-4 2.12-6.05.26-.83 0-1.57-1.13-1.3-2.82.71-5.62 1.49-8.44 2.2a13.71 13.71 0 01-2 .23 39.42 39.42 0 00-5.34.52c-2.11.49-4.13 1.35-6.2 2a2.72 2.72 0 01-1.17.09 8.16 8.16 0 00-5.1.92 7.63 7.63 0 01-2.09.73c-4.18.89-8.38 1.67-12.55 2.62-1.65.37-3.2 1.23-4.85 1.62-4.47 1.05-9 2-13.76 3a9.06 9.06 0 001 1.26c1.25 1 2.61 1.93 3.79 3s2 2.35 3.13 3.41c.89.83 2 1.39 3 2.2 1.71 1.58 3.3 3.28 5.1 4.76 2.48 2.06 5.11 3.94 7.63 6a14.48 14.48 0 012.31 2.32c1.11 1.4 1.87 3.2 3.21 4.29a33.73 33.73 0 017.76 8 74.65 74.65 0 005.82 7.29c.84 1.05 1.18 2.75 3.53 2.28z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_2"
        d="M70.07 31.63c-.59-1.58-1.89-1.4-4.73-.45-2.51.83 0 0-7.19 1.67S50.33 35.72 42.58 36s-3.62 5.22-.18 8 4.72 4.49 7.81 7.46a77.51 77.51 0 016.38 6.83c1.2 1.43 2.27 2.58 4.94.18 2.4-2.16 1.53-4.83 2.25-8.18s2.61-7.12 3.68-9.79c1.22-3 2.4-3.56 3.6-5.48s-.99-3.39-.99-3.39zm-10.15 12c-.32 1-2.34 7.09-2.07 7.36S55.1 49.51 52 46c-.9-1-5.21-4.71-5.21-4.71S63.24 36.88 63.51 37s-3.28 5.58-3.59 6.62z"
      />
    </svg>
  );
}
