import React from "react";

export function TriangleLines2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <g>
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M4.78 80c-.18-3.62 2.33-5.71 4.41-8.16s3.73-5.21 5.61-7.84c3.32-4.64 6.71-9.24 10-13.92 1.48-2.13 2.65-4.46 4.05-6.63a49.73 49.73 0 013-4c1.48-1.88 3.1-3.67 4.51-5.6a33.17 33.17 0 015.13-6.22A23.37 23.37 0 0044.36 24c1.16-1.48 2.4-2.89 3.44-4.44.9-1.35 1.52-2.89 2.36-4.29A23.51 23.51 0 0151.93 13l1.91-2.38c.47-.57 1-1.11 1.43-1.67.62.67 1.51 1.23 1.78 2 .57 1.64.63 3.46 1.23 5.08.83 2.26 1.95 4.42 3 6.62.39.84.83 1.66 1.2 2.51 1.42 3.24 3.51 6.12 4.49 9.62.72 2.6 2.4 4.92 3.58 7.39.94 2 1.71 4 2.64 6 1.31 2.79 2.76 5.51 4 8.31 1 2.26 1.81 4.62 2.79 6.9A38.31 38.31 0 0081.85 67c1.12 2 2.31 4 3.4 6 1.21 2.21 2.06 4.68 3.59 6.62 1.34 1.69 1.06 2.68-.1 4.14-1 1.29-2 2.42-3.82 2.44-1.16 0-2.33.3-3.49.29a29.07 29.07 0 01-3.82-.38q-3.56-.51-7.09-1.12a12.56 12.56 0 01-3.76-.91c-1.79-1-3.47-.5-5.29-.39a14.09 14.09 0 01-5.92-.69c-1.74-.66-3.82-.5-5.75-.51-2.47 0-4.94.21-7.41.26-3.46.07-6.94-.15-10.37.17a28.62 28.62 0 01-9.14-.67c-.6-.14-1.3.21-1.95.21-1.92 0-3.84-.14-5.76-.15a10.86 10.86 0 00-3.48.21 3.63 3.63 0 01-4.19-1.09A7.82 7.82 0 005 80.31zm77-1c-1.7-3.07-3.45-6.25-5.23-9.41-1-1.85-2.3-3.6-3.16-5.53-2.34-5.24-3.87-10.82-7-15.76-1.64-2.56-2.33-5.71-3.56-8.55-1-2.28-2.12-4.5-3.24-6.73-.75-1.47-1.69-2.84-2.37-4.34-.92-2-1.63-4.19-2.54-6.24-.37-.83-1.12-1.21-1.8-.22-1.72 2.52-3.38 5.08-5.1 7.59a14.55 14.55 0 01-1.44 1.6 43.67 43.67 0 00-3.83 4.12c-1.32 1.86-2.31 3.94-3.47 5.91a3 3 0 01-.86.88 8.52 8.52 0 00-3.37 4.27 7.79 7.79 0 01-1.13 2c-2.67 3.61-5.43 7.15-8 10.79-1 1.45-1.66 3.2-2.69 4.65-2.85 3.97-5.75 7.84-8.8 11.97a9.29 9.29 0 001.69.27c1.7-.06 3.4-.3 5.09-.26s3.22.46 4.83.51c1.28 0 2.57-.32 3.86-.33 2.44 0 4.89.22 7.33.14 3.38-.12 6.76-.46 10.14-.63a15.24 15.24 0 013.44.22c1.85.33 3.69 1.21 5.5 1.14 4-.17 7.81-.21 11.71.93a81.24 81.24 0 009.64 1.68c1.39.25 2.84 1.33 4.36-.67z"
        />
        <path
          className="sg-shapes--shape_path sg-shapes--shape_color_1"
          d="M52.5 38c-1.55-.83-2.45.22-4 2.94-1.4 2.4 0 0-4.49 6.32S39.84 55 33.91 60.54s.79 6.62 5.4 6.39 6.83.24 11.33.42a86.19 86.19 0 019.77.93c1.94.3 3.58.46 4-3.29.38-3.36-2.15-4.86-3.92-8s-2.91-7.42-3.92-10.26c-1.14-3.21-.6-4.47-1-6.81S52.5 38 52.5 38zm.36 16.49c.48 1 3.11 7.21 3.51 7.23s-3.19.76-8.1.11c-1.42-.19-7.37-.08-7.37-.08s9.89-14.87 10.2-15 1.29 6.71 1.76 7.74z"
        />
      </g>
    </svg>
  );
}
