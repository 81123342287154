import React from "react";

export function Composite4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M43.285 35.708c-3.079 2.7-3.433 2.22-4.36 3.364a43.6 43.6 0 01-4.326 3.822 31.129 31.129 0 01-4.04 2.827c-.721.286-4.932 3.078-6.076 4.795-1.145 1.716-2.289 2.437 1.854 5.15a74.836 74.836 0 019.303 7.015c1.282 1.281 10.014 10.15 11.581 9.864 1.568-.286 2.289-2.14 2.289-4.005 0-1.866-1.327-2.793-5.905-5.791-4.577-2.998-7.015-6.465-9.361-8.01a21.089 21.089 0 01-3.685-3.079s4.429-3.433 5.86-3.856c0 0 3.868-3.433 5.149-4.44a24.548 24.548 0 015.116-3.365c1.567-.572 3.501-3.032 1.647-4.326a4.062 4.062 0 00-5.046.035zM52.737 4.364c-.958.9-2.032 1.67-3.193 2.288-.949.378-1.956 2.049-4.577 2.861-2.621.813-2.666.332-3.673 1.145a27.376 27.376 0 01-5.814 3.433c-1.865.663-3.959.709-5.813 4.234-1.854 3.524-1.385 4.486 0 5.149 1.384.664 5.104 2.198 6.866 3.296a51.548 51.548 0 007.576 4.097c2.62.904 6.534 3.25 7.244 2.483.709-.766.698-2.037 1.144-2.952.858-1.774-1.144-2.289-2.14-2.861a49.202 49.202 0 01-7.107-3.101c-3.238-1.911-2.666-2.106-4.291-2.438-1.625-.332-3.101-1.613-3.101-2.288 0-.676 4.909-2.289 6.111-3.434 1.201-1.144 3.719-.766 5.149-1.853 1.431-1.088 3.044-2.438 4.429-2.633 1.385-.194 4.772-3.089 5.871-5.092 1.098-2.003 1.43-3.868-.344-4.2-1.773-.332-3.089.813-4.337 1.866z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_2"
        d="M52.886 18.908c1.671 0 6.866 3.662 8.766 4.292 1.9.629 2.495 1.339 4.486 1.568 1.991.228 3.811 1.808 5.195 2.094 1.385.286 2.964 1.911 4.44 2.151 1.477.241.573 1.717.092 2.621-.43.917-.763 1.875-.996 2.861-.389 1.293-4.863.812-8.01 3.433-3.147 2.62-2.163.835-5.333 3.238-2.632 1.923-3.067 2.484-4.394 3.067a6.993 6.993 0 00-2.541 1.705 38.132 38.132 0 01-3.101 2.575c-1.522 1.293-2.621 2.381-4.726.572-2.106-1.808-.801-3.192.721-4.428a26.768 26.768 0 014.577-3.101c.904-.39 2.861-2.152 5.001-3.628s4.246-4.051 6.866-4.715c2.621-.664 4.166-1.671 4.395-2.106.228-.434-4.395-1.43-6.638-2.288-2.243-.859-4.005-2.049-4.955-2.289-.95-.24-3.57-2.289-4.955-2.575-1.385-.286-2.106-1.316-1.144-2.094 1.201-1.053.206-2.953 2.254-2.953zM49.739 52.564c-.904 1.053-1.762 2.106-1.385 3.102.378.995 2.198 2.38 3.811 3.147 1.863.938 3.65 2.02 5.344 3.238 2.094 1.431 4.383 3.674 5.196 4.063.812.389 2.288.995 2.048 1.281-.24.286-3.616 2.003-5.241 3.433a43.895 43.895 0 01-5.722 3.285c-2.06 1.144-2.003 1.865-2.048 4.062-.046 2.197.755 2.381 2.14 2.049a20.717 20.717 0 005.287-2.804c.949-.767 4.096-2.289 5.435-3.765 1.339-1.476 6.5-2.529 7.05-2.907.904-.629 1.339-2.506 1.625-3.627.24-.95-.573-1.522-3.433-3.102a36.051 36.051 0 01-7.542-5.195c-1.144-1.293-5.241-2.689-6.099-3.628a18.054 18.054 0 00-4.818-2.998c-.835-.343-.79-.629-1.648.366z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M40.39 72.499c-2.221 1.911-4.429 5.287-6.867 6.672-2.437 1.385-3.627 2.106-4.817 3.628-1.191 1.522-2.198 1.384-2.621 3.433-.424 2.048-.858 3.433 1.236 3.341 2.094-.091 2.289-.103 3.341.515 1.053.618 3.433.813 4.383 1.248.95.434 4.91 2.288 6.867 2.86 1.956.573 5.721 2.953 7.301 3.331 1.579.377 2.288-2.518 2.185-4.051-.103-1.534-6.191-2.953-8.388-4.429-2.197-1.476-2.151-1.671-4.188-1.717a10.656 10.656 0 01-5.15-2.105c-.343-.378 4.578-3.72 6.054-4.91s3.33-4.245 3.811-4.291c.48-.046 3.147-2.14 2.197-3.433-.95-1.293-2.907-2.186-5.344-.092z"
      />
    </svg>
  );
}
