import React from "react";

export function Composite10() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M41.21 5C37.05 15.37 24 49.48 22 52.35S1.29 90.83 4.45 90.83 30 88.54 37.19 87.1 92.61 78.77 94 76.76 71.36 46.61 65.9 38.57 42.36 2.1 41.21 5zM95.19 81.36c-.75.61-13 2.78-16.63 3.08a217.05 217.05 0 00-27.3 4.1c-5.59 1.23-23.55 4-29.58 4.88s-10.62 1.18-16.3 1.79c-2.23.24-.64-2.08 1.66-2.37S20 91.41 27.71 90.26s24.7-2.87 28.14-4.26 6.88-.7 9.41-.91 17.28-2.53 19.27-3.13a52.29 52.29 0 017.76-1.5c4.32-.38 4.03-.02 2.9.9z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_2"
        d="M77.4 35.64c2.19-2.64 4.84-4.42 6.13-3S83 38.76 80.84 42 61.78 71.6 60.27 73.76s-9.47 12.7-10.44 12.7-2-1.29-.54-4.09 9.8-17.44 12.92-22.72S75.24 38.22 77.4 35.64z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_2"
        d="M65.94 36.79c3.24-3.56 5.71-6.89 7-5.82s0 4.85-2.26 9.91-14.43 23.48-18.63 29.51-13 17.76-14 17.76-3.12 1.51.32-5.81 18.96-36.08 27.57-45.55z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_2"
        d="M87.26 39.91c3-1.46 4.85 1.72 4.31 2.69a58.47 58.47 0 01-5.92 11.74c-4.31 6.57-9.59 13.78-11.09 17s-10.23 14-11.42 14.22a2.73 2.73 0 01-2.8-1c-.53-.94 3-6.89 4-9s18.29-33.39 22.92-35.65z"
      />
    </svg>
  );
}
