import React from "react";

export function Composite5() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M67.178 40.74c-.072 4.913.643 4.863.318 6.6a52.286 52.286 0 00-.126 6.924c.031 1.974.219 3.943.561 5.888.299.881 1.024 6.899.347 9.28-.678 2.38-.446 3.986-6.146 2.312a89.927 89.927 0 00-13.644-3.041c-2.167-.174-17.06-1.25-18.018-2.905-.958-1.654.174-3.755 1.878-5.206 1.704-1.451 3.583-.959 9.882.89 6.299 1.848 11.362 1.378 14.598 2.319 1.849.539 3.754.865 5.678.971 0 0-.309-6.716-1.035-8.352 0 0 .127-6.203.05-8.157a29.465 29.465 0 00-.906-7.289c-.697-1.877.046-5.557 2.669-4.87a4.873 4.873 0 013.894 4.636zM88.454 7.726a17.852 17.852 0 00.393 4.696c.394 1.162-.349 3.381.948 6.407 1.296 3.025 1.771 2.693 1.812 4.245a32.814 32.814 0 001.386 7.98c.845 2.22 2.432 4.168.655 8.603-1.778 4.435-3.021 4.754-4.704 4.006-1.683-.748-5.977-2.953-8.351-3.708a61.809 61.809 0 01-9.635-3.733c-2.864-1.69-8.051-3.44-7.902-4.684.148-1.245 1.317-2.222 1.806-3.342.953-2.164 2.981-.735 4.278-.271a58.88 58.88 0 008.36 4.079c4.265 1.471 3.997.797 5.564 2.023 1.568 1.227 3.887 1.578 4.503 1.053.617-.525-1.728-6.265-1.617-8.252.11-1.988-2.193-3.994-2.313-6.146-.119-2.152-.141-4.676-1.041-6.092-.899-1.416-.889-6.762.085-9.323.975-2.561 2.42-4.315 4.103-2.953 1.683 1.362 1.662 3.454 1.67 5.412z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_2"
        d="M75.054 18.904c-1.3-1.526-8.686-3.423-10.738-4.669-2.053-1.245-3.164-1.237-4.922-2.878-1.758-1.64-4.615-2.074-5.954-3.116-1.338-1.042-4.051-1.221-5.418-2.382-1.368-1.161-2.013.813-2.465 1.955a17.596 17.596 0 01-1.839 3.135c-.878 1.361 3.041 5.074 3.095 9.987.055 4.912.92 2.625 1.19 7.389.292 3.9.118 4.733.617 6.4a8.392 8.392 0 01.419 3.646 45.625 45.625 0 00.061 4.836c.002 2.396-.136 4.245 3.153 4.762 3.29.516 3.54-1.752 3.485-4.104a32.195 32.195 0 00-.728-6.593c-.348-1.128-.261-4.286-.577-7.389-.316-3.103.398-7.029-1.034-9.939-1.433-2.91-1.715-5.104-1.495-5.652.219-.547 4.724 2.901 7.253 4.282 2.528 1.382 4.986 2.065 5.945 2.746.958.681 4.867 1.481 6.206 2.523 1.338 1.042 2.839.9 2.803-.584.026-1.916 2.536-2.484.943-4.355zM46.761 47.957c-.258 1.645-.552 3.247-1.755 3.677-1.203.429-3.883-.155-5.839-1.033a47.876 47.876 0 00-7.114-2.362c-2.936-.8-6.765-1.146-7.752-1.585-.988-.44-2.69-1.316-2.764-.874-.075.442.983 4.86.941 7.457a52.424 52.424 0 001.451 7.781c.557 2.772-.146 3.28-2.117 5.031-1.972 1.751-2.762 1.162-3.536-.361a24.84 24.84 0 01-1.552-7.01c-.038-1.464-1.096-5.522-.789-7.893.307-2.372-2.746-7.904-2.828-8.7-.129-1.315 1.247-3.172 2.049-4.306.681-.958 1.835-.661 5.503.724a43.31 43.31 0 0010.611 2.846c2.072.04 6.533 2.696 8.058 2.75a21.644 21.644 0 016.486 2.068c.963.496 1.189.232.947 1.79z"
      />
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M35.826 72.003c-.019 3.514-1.384 8.157-.753 11.46.631 3.304.899 4.952.434 7.223-.464 2.27.445 3.083-1.097 5.063-1.542 1.981-2.468 3.455-4.013 1.471-1.546-1.984-1.687-2.171-3.07-2.652-1.383-.481-3.412-2.503-4.548-3.033-1.136-.529-5.91-2.703-7.954-4.046-2.045-1.342-7.148-2.929-8.721-4.078-1.573-1.149.519-4.049 2-5.147 1.481-1.099 7.512 3.358 10.57 4.216 3.057.859 3.199.666 4.825 2.491a12.774 12.774 0 005.929 3.065c.612.02-.163-7.074-.225-9.348-.061-2.274 1.288-6.344.956-6.818-.332-.475-.493-4.539 1.426-4.677 1.92-.139 4.258.954 4.241 4.81z"
      />
    </svg>
  );
}
