import React from "react";

export function Blob4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M84.72 34.9c-.88-4.11-2.33-5.57-4.82-6.68s-5.77-3.3-10.25-4.43a98.11 98.11 0 01-14.22-5.31c-3.73-1.75-15.14-4.94-18.19-5.77S24.5 6.89 21.46 8.56s-7.2.27-9.14.27S8.44 9.94 8.72 13s3.32 6.65 1.66 9.69-5.27.56-7.2 3.88S1.24 34 4.84 39s9.42 22.16 20.22 29.09 9.28 13.34 22.43 15.81S58.57 95.63 64.67 90c2.32-2.15 2.39-6.07 4.06-7.11s7.27-1.45 8.93-3.74c1.38-1.9.53-12 2.49-13.71 2.68-2.26 7.94 2.9 7.4-3.24-.16-1.81-3.66-5.9-1.37-8.81s5.4-3.32 7.89-4.15c1.87-.62 4.8-2.42 3.87-3.66a28.92 28.92 0 00-7.38-5.82c-2.7-1.36-5.22-1.96-5.84-4.86z"
      />
    </svg>
  );
}

