import React from "react";

export function TriangleGroup4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M57.2 5.29c-1.64 2-8.06 4.61-11.68 7.9s-14.32 9.87-17.28 11-10.86 5.92-10.7 7.4 3.46 3.95 9.88 5.6S41.24 43.14 46.34 44s16.95 6.08 18.27 5.1.29-9.52.33-12c.16-10.86.16-21.56-.16-24.36-.39-3.25 1-6.91-.5-8.55-.62-.75-5.43-.88-7.08 1.1zM48.32 58.61C41.93 56.55 35 54.5 33.67 54s-5.1-.65-5.27 1.49 1.48 3.29 3.29 5.26 3.79 7.24 5.6 11.52S44.37 88.73 46 90.71s.29 2.13 4 4.93c2.8 2.14 2.64-.82 11-7.9S76.46 72.6 79.59 71.45s3.12-2 2.63-3.29-7.08-2.8-8.89-3.62-19.42-4.12-25.01-5.93z"
      />
    </svg>
  );
}
