import React from "react";

export function LineGroup3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="sg-shapes--shape_svg">
      <path
        className="sg-shapes--shape_path sg-shapes--shape_color_1"
        d="M25.48 76.7c.49 2.93-3.45 5.94-5 6.89s-3.07 2.88-4.6 2.11-3.26-2.49-1-3.83a20.49 20.49 0 005.37-4.41c1.59-1.72 4.66-4.21 5.23-.76zM52.11 32.06c-4 4.28-7.09 5.36-8.24 6.51s-1.91 1.54-3.64-.19-3.06-3.45-.19-4.21A15.26 15.26 0 0047.13 30c1.53-1.72 3.83-3.83 5.17-3.25s3.07 0 2.49 2.1-.38.72-2.68 3.21zM91.38 34.36c-2.53 3.64-4.76 4.7-5.51 5.64s-1.28 1.33-2.73.21-2.63-2.27-.56-3.13a11.47 11.47 0 004.88-3.84c1-1.44 2.48-3.24 3.54-3s2.29-.3 2.07 1.33-.22.7-1.69 2.79zM75.87 7.92c2.29 1.34-.77 5-1.73 5.94s-8.81 5-9.77 6.13-1 3.45-2.68 2.68-4.79-1.91-4-3.83 1.91-.76 4.21-2.68c1.61-1.34 6.1-3.35 8.62-6.71 2.28-3.06 4.32-2.13 5.35-1.53zM82.19 24.21c-1.08 4.1-3.83 5-5 6.32s-1.15 2.87-2.69 2.3-5.5-2.49-3.81-3.45 4.6-4.79 5.56-5.17a10 10 0 003.45-2.49c.96-1.15 3.45-1.15 2.49 2.49zM58.05 42.21c-3 3.71-4.22 4.79-6.32 5.75s-3.45 1.34.38 3.83 5.75-2.68 7.47-3.64 5.17-4.4 5.17-6.51-1.15-3.26-2.49-2.64a10 10 0 00-4.21 3.21zM75.29 48.15c-2.9-.38-6.13 2.68-8.24 5.56s-2.49 4.6.77 6.32 4.79-4 6.13-6.9 4.21-4.59 1.34-4.98zM51.34 68.84c-3.22 2.54-7.28 3.07-6.32 5.56s3.26 4.79 4.79 3.26a29.49 29.49 0 014.6-4.22c1.34-.77 4.57-5.69 3.83-7.09-1.53-2.87-4.24.39-6.9 2.49zM44.45 60c-1.92 2.87-4 7.47-6.71 7.86s-5.75.38-4.4-2.11 4.21-7.67 7.28-8.81 4.21-1 5.74.57S44.45 60 44.45 60zM35.06 44.7a16.4 16.4 0 01-4.41 6.9c-1.72 1.73-1.34 4.41-3.06 3.07s-5.17-1.92-3.83-4.22 3.25-5 5.74-6.13 6.03-.89 5.56.38zM19.35 63.29c-2 4.55-3.45 5.17-5.36 6.51s-2.69 3.07-4.41 1.92-3.45-2.11-2.3-2.72 5.88-3.52 7.28-5.36c3.07-3.99 5.56-2.08 4.79-.35zM38.32 84.94c1.91 2.63-.58 3.83-2.49 5.55s-2.3 3.07-4.22 1.53-4-1.53-2.49-3.06 4.41-1.53 5.36-3.07 2.3-3.06 3.84-.95z"
      />
    </svg>
  );
}
